import React, { useRef, useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { Masonry } from '@mui/lab'

import Teaser, { TeaserProps } from '@components/core/teaser'
import Image from '@components/media/image'
import { scrollToSection } from '@system/utils/scrollToSection'
import { useIntl } from 'react-intl'

export type M003TeaserMasonryProps = BASE.Helpers.ModuleDefaultProps & {
  mediaDesktop?: BASE.Contentful.IAssetImage
  mediaMobile?: BASE.Contentful.IAssetImage
  page?: {
    titleNavigation: string
    subPages: Array<TeaserProps & { id: string }>
  }
}

export default function M003TeaserMasonry({
  mediaDesktop,
  mediaMobile,
  page,
}: M003TeaserMasonryProps) {
  const MasonryRef = useRef<HTMLDivElement>(null)
  const HeadlineRef = useRef<HTMLElement>(null)
  const [masonryHeight, setMasonryHeight] = useState(0)
  const intl = useIntl()
  if (!page) return null

  useEffect(() => {
    if (!MasonryRef.current) return
    let minHeightOdd = 0
    let minHeightEven = 0
    Array.from(MasonryRef.current.childNodes)
      .filter((node) => (node as HTMLElement).tagName === 'DIV')
      .forEach((node, index) => {
        if (index % 2) {
          minHeightEven +=
            (node as HTMLElement).getBoundingClientRect().height + 48 // the 48 is the margin
        } else {
          minHeightOdd +=
            (node as HTMLElement).getBoundingClientRect().height + 48
        }
      })
    setMasonryHeight(
      Math.ceil(minHeightEven > minHeightOdd ? minHeightEven : minHeightOdd) +
        60
    )
  }, [MasonryRef.current])

  const elementId = page.titleNavigation
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase()

  useEffect(() => {
    if (location.href.includes(`#${elementId}`)) {
      scrollToSection(elementId, -60)
    }
  }, [])

  const titleNavigation =
    page.titleNavigation?.split(' ').length - 1 === 1
      ? page.titleNavigation.split(' ').join('\n')
      : page.titleNavigation

  return (
    <Box
      component="section"
      role="region"
      aria-label={`${titleNavigation} - ${intl.formatMessage({
        id: 'teaserMasonary.ariaLabel',
      })}`}
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      {!!mediaDesktop?.localFile && (
        <Image
          ariaHidden={true}
          image={mediaDesktop}
          imageMobile={mediaMobile}
          sx={(theme) => ({
            position: 'absolute',
            maxWidth: theme.maxWidth.xl,
            zIndex: 5,
            top: `${
              (HeadlineRef.current?.getBoundingClientRect().height || 0) + 32
            }px`, // 32 is the paddingTop of the container
            right: 0,
            [theme.breakpoints.up('lg')]: {
              top: 0,
            },
          })}
        />
      )}
      <Container
        sx={(theme) => ({
          position: 'relative',
          zIndex: 10,
          paddingTop: { xs: 8, lg: 50 },
          marginBottom: theme.spacing(10),
          [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(16),
          },
        })}
      >
        <Typography
          ref={HeadlineRef}
          id={elementId}
          variant="h2"
          sx={(theme) => ({
            color: theme.palette.text.tertiary,
            marginBottom: 24,
            maxWidth: '80%',
            whiteSpace: 'pre-line',
            [theme.breakpoints.up('lg')]: {
              marginBottom: 10,
              maxWidth: '50%',
            },
          })}
        >
          {titleNavigation}
        </Typography>
        <Masonry
          role="group"
          aria-label={`${titleNavigation} - ${intl.formatMessage({
            id: 'teaserMasonary.teasers.ariaLabel',
          })}`}
          ref={MasonryRef}
          columns={{ xs: 1, lg: 2 }}
          spacing={{ xs: 3, lg: 8 }}
          defaultHeight={500}
          defaultColumns={2}
          defaultSpacing={4}
          sx={(theme) => ({
            minHeight: `${masonryHeight}px !important`,
            WebkitFilter: `drop-shadow(0px 2px 22px rgba(0, 0, 0, 0.15))`,
            [theme.breakpoints.up('xs')]: {
              marginLeft: 0,
              marginRight: 0,
            },
          })}
        >
          {page.subPages.map((page, index) => (
            <Teaser
              tabIndex={0}
              role="listitem"
              key={page.id}
              {...page}
              sx={(theme) => ({
                order: `${index % 2 ? 2 : 1} !important`,
                marginLeft: '0px !important',
                marginRight: '0px !important',
                marginBottom: `${theme.spacing(6)} !important`,
                WebkitMaskImage: '-webkit-radial-gradient(white, black)',
                [theme.breakpoints.up('lg')]: {
                  marginBottom: `${theme.spacing(8)} !important`,
                  marginLeft: `${index % 2 ? theme.spacing(4) : 0} !important`,
                  marginRight: `${index % 2 ? 0 : theme.spacing(4)} !important`,
                },
              })}
            />
          ))}
        </Masonry>
      </Container>
    </Box>
  )
}

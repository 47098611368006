import React from 'react'

import TeaserDefault from './variants/default'

export type TeaserProps = BASE.Helpers.IReactDefaultProps & {
  teaserImage: BASE.Contentful.IAssetImage
  teaserTitle: string
  teaserCopy: string
  fields: BASE.Contentful.IFields
  type?: 'default'
  showTeaserImageOnHomepage?: boolean
  role?: string
  tabIndex?: number
}

export default function Teaser(props: TeaserProps) {
  switch (props.type) {
    case 'default':
      return <TeaserDefault {...props} />
    default: {
      return <TeaserDefault {...props} />
    }
  }
}

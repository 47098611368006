import React from 'react'
import { Paper, Typography } from '@mui/material'

import { TeaserProps } from '..'
import { Box } from '@mui/system'
import IconButton from '../../IconButton'
import Link from '../../link'
import Image from '@components/media/image'

export default function TeaserDefault({
  teaserTitle,
  teaserCopy,
  teaserImage,
  fields,
  sx,
  showTeaserImageOnHomepage,
  role,
  tabIndex,
}: TeaserProps) {
  return (
    <Paper
      role={role}
      sx={[
        (theme) => ({
          boxShadow: theme.boxShadows.teaser,
          borderRadius: '32px',
          overflow: 'hidden',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!!teaserImage && !!showTeaserImageOnHomepage && (
        <Link
          aria-hidden="true"
          to={fields.fullPath}
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
        >
          <Image
            ariaHidden={true}
            image={teaserImage}
            sx={{
              width: '100%',
              height: 'auto',
              maxHeight: { xs: '200px', lg: '300px' },
            }}
          />
        </Link>
      )}
      <Box
        sx={(theme) => ({
          padding: theme.spacing(6, 4, 0, 4),
          marginBottom: theme.spacing(4),
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(10, 10, 0, 10),
          },
        })}
      >
        <Link
          to={fields.fullPath}
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
        >
          <Typography
            component="h3"
            variant="h4"
            sx={{ marginBottom: { xs: 2, lg: 4 } }}
          >
            {teaserTitle}
          </Typography>
        </Link>
        <Typography sx={{ marginBottom: 4 }}>{teaserCopy}</Typography>
      </Box>
      <IconButton
        tabIndex={tabIndex}
        name="ArrowRight"
        to={fields.fullPath}
        sx={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: { xs: 2, lg: 4 },
          marginBottom: { xs: 2, lg: 4 },
        }}
      />
    </Paper>
  )
}
